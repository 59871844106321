import * as React from 'react';
import {ConsumerWrapper, IndustriesWrapper} from './Styled';
import NumberedSelector from './NumberedSelector';

const RealEstate = () => {
  return (
    <IndustriesWrapper>
      <div className="image">
        <img src="/images/industries/real-estate.jpg" />
      </div>
      <div className="wrapper">
        <h1 className='text-4xl font-bold font-display my-4'>Real Estate</h1>
        <p className='my-3'>
          Zegates, as a forward-thinking software company, has the potential to
          revolutionize the real estate landscape through its innovative
          solutions. By developing cutting-edge property management software, We
          can empower real estate professionals to streamline operations, from
          efficient rent collection and lease management to prompt handling of
          maintenance requests. The integration of data analytics and predictive
          modeling tools would enable users to make informed decisions by
          leveraging market trends, pricing insights, and demand forecasts.
        </p>

        <NumberedSelector data={data}>
          <p className='font-semibold'>
            Partner with us with for intelligent consumer market development
          </p>
          <div className="wrapper"></div>
        </NumberedSelector>
        <div className="section">
          <div>
            <div>
              <p className='text-sm font-semibold'>ENHANCE YOUR CONSUMER MARKETS</p>
              <h2 className='text-2xl my-2 font-bold'>Zegates Product Engineering</h2>
            </div>
            <p className='leading-relaxed'>
              Use negates expertise in product engineering to enhance your
              consumer market goals by innovating your existing business or
              starting new one. We can bring right tech to your door step with
              minimal time to develop. And it will be always data driven and we
              will be using KPIs to manage and grow your business rapidly
            </p>
          </div>
          <div>
            <img src="/images/home/consumer-zegates.jpg" />
          </div>
        </div>
        <div className="section">
          <div>
            <img src="/images/home/consumer-netlise.jpg" />
          </div>
          <div>
            <h2 className='text-2xl my-2 font-bold'>Netlise SaaS</h2>
            <p className='leading-relaxed'>
              Searching for an all in one product suite? We created Netlise to
              make your world easier to manage the products and achieve consumer
              market goals quickly. Netlise offers streamlined functionality for
              E-commerce, Point of sale and CRM in a SaaS platform with offline
              and online capabilities.
            </p>
          </div>
        </div>
      </div>
    </IndustriesWrapper>
  );
};

const data = [
  {
    title: 'Software Driven',
    content: (
      <>
        <h2 className='text-3xl font-bold my-8'>Manage properties with software</h2>
        <p className='my-3 leading-relaxed'>
          Utilise robust property management software that streamlines tasks
          such as rent collection, lease management, maintenance requests, and
          communication between property managers and tenants.
        </p>
        <p className='my-3 leading-relaxed'>
          Zegates has been providing services to real state management agencies
          to inspect properties and evaluate when assigning new tenants. With
          inspection sheet builders the customising capabilities are more
          versatile than traditional flow. Also Zegates has been creating
          software which gives institutes such as cleaning and house keeping to
          provide services to customers digitally.
        </p>
      </>
    ),
  },
  {
    title: 'Data Analytics and Predictive Modeling',
    content: (
      <>
        <h2 className='text-3xl font-bold my-8'>Forecast your real estate management</h2>
        <p className='my-3 leading-relaxed'>
          Provide data analytics and predictive modeling tools to help real
          estate professionals make informed decisions based on market trends,
          pricing data, and demand forecasts
        </p>
        <p className='my-3 leading-relaxed'>
          Also Zegates expertise in machine learning algorithms for more
          accurate property valuation, taking into account various factors such
          as location, amenities, market trends, and historical data
        </p>
      </>
    ),
  },
];

export default RealEstate;
