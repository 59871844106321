import * as React from 'react';
import Layout from '../components/Layout';
import Footer from '../components/Footer';
import RealEstate from '../modules/industries/RealEstate';

const IndexPage = () => {
  return (
    <Layout>
      <RealEstate />
      <Footer />
    </Layout>
  );
};

export default IndexPage;
